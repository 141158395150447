<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    class="gl-modal__create-promo"
    closable
    full-buttons
    :max-height-form="windowHeight < 650 ? '40vh' : '70dvh'"
    :overflowing-form="windowHeight < 650"
    :side-valid="!areFieldsValid"
    :submit-title="isEditMode ? 'Update' : 'Create'"
    title="Create new promo"
    width="480"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="submit"
  >
    <template #mainSlot>
      <div class="buy-modal__content">
        <div class="buy-modal__row">
          <div class="buy-modal__row-label">
            Name
          </div>
          <input 
            v-model="name"
            class="buy-modal__row-input"
            :class="{'buy-modal__row-input--disabled' : isEditMode && activePromo.enabled}"
            type="text"
          >
        </div>
        <div class="buy-modal__row">
          <div class="buy-modal__row-label">
            Expire Date
          </div>
          <date-picker
            v-model="expiresAt"
            class="buy-modal__datepicker"
            :disabled-date="disabledBeforeToday"
            :show-second="false"
            type="datetime"
          />
        </div>
        <div class="buy-modal__row">
          <div class="buy-modal__row-label">
            Tokens
          </div>
          <input 
            v-model="tokens"
            class="buy-modal__row-input"
            :class="{'buy-modal__row-input--disabled' : isEditMode && activePromo.enabled}"
            type="text"
          >
        </div>
      </div>
    </template>
  </gl-modal>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';

// components
import GlModal from '@/components/gl-modal'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

// mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlModal,
    DatePicker,
  },
  mixins: [deviceWidthMixin],
  props: {
    modalState: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      name: null,
      expiresAt: null,
      tokens: null,
    }
  },
  computed: {
    ...mapGetters('adminPanel', ['activePromo', 'isEditMode']),
    areFieldsValid() {
      return this.name && this.name.trim() !== '' && this.expiresAt && this.tokens && this.tokens != 0
    },
  },
  watch: {
    modalState(newValue) {
      if(newValue && this.isEditMode) {
        this.name = this.activePromo.name
        this.expiresAt = new Date(this.activePromo.expiresAt)
        this.tokens = this.activePromo.tokens
      } else {
        this.name = null
        this.expiresAt = null
        this.tokens = null
      }
    },
    tokens(newVal) {
      if(typeof newVal === 'string') {
        this.tokens = newVal.replace(/\D/g, '')
      }
    },
  },
  methods: {
    submit() {
      this.$emit('createNewPromo', {
        name: this.replaceSpaces(this.name),
        key: this.replaceSpaces(this.name).toLowerCase(),
        expiresAt: this.formatDate(this.expiresAt),
        tokens: this.tokens,
      })
    },
    replaceSpaces(str) {
      return str.trim().replace(/ /g, '_').toUpperCase()
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
    formatDate(date) {
      return new Date(date).toISOString()
    },
  }
}
</script>

<style scoped>
.buy-modal__content {
  padding: 24px;
}
.buy-modal__row + .buy-modal__row {
  margin-top: 16px;
}
.buy-modal__row-label {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
}
.buy-modal__row-input {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--reflex-bluet);
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  color: var(--dark);
}
.buy-modal__row-input--disabled {
  opacity: 0.5;
  background-color: var(--light-grey-e-f);
  pointer-events: none;
}

.gl-modal__create-promo::v-deep .gl-modal .gl-form__actions {
  margin: 0;
  padding: 24px;
  border-top: 1px solid var(--light-grey-e-f);
}

.buy-modal__datepicker::v-deep .mx-input {
  height: 40px;
  padding: 0 16px;
  background-color: var(--white) !important;
  border: 1px solid var(--reflex-bluet);
  box-shadow: none;
  font-size: 16px;
  line-height: 1;
}
.buy-modal__datepicker::v-deep .mx-icon-calendar svg {
  fill: var(--reflex-bluet);
}
.buy-modal__datepicker::v-deep .mx-input {
  color: var(--dark);
}

@media screen and (max-height: 767px) {
  .gl-modal__create-promo::v-deep .gl-modal .gl-form__actions {
    margin: 0;
    padding: 16px;
  }
  .buy-modal__content {
    padding: 16px;
  }
}
</style>