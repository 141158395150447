<template>
  <div class="table-item">
    <div 
      class="table-item__row table-item__row--first"
      :class="{ 'table-item__row--enabled' : tableItemData.enabled }"
    >
      <div class="table-item__row-label">
        Name:
      </div>
      <div
        class="table-item__name-pointer"
        @click="copyCode()"
      >
        {{ tableItemData.name }}
      </div>
    </div>
    <div class="table-item__row">
      <div class="table-item__row-label">
        Expire Date:
      </div>
      {{ formatDate(tableItemData.expiresAt, 'dd.MM.yyyy HH:mm') }}
    </div>
    <div class="table-item__row table-item__row--auto">
      <div class="table-item__row-label">
        Tokens:
      </div>
      {{ tableItemData.tokens }}
    </div>
    <div class="table-item__row table-item__row--auto">
      <div class="promo__cntrl-wrap">
        <div
          v-if="tableItemData.enabled" 
          class="promo__cntrl-item"
          @click="copyLink()"
        >
          <gl-icon
            :height="20"
            :name="'copy-link'"
            :width="20"
          />
        </div>
        <div 
          v-if="tableItemData.enabled" 
          class="promo__cntrl-item"
          @click="generateQRCode()"
        >
          <gl-icon
            :height="22"
            :name="'qrcode'"
            :width="22"
          />
        </div>
        <div
          class="promo__cntrl-item"
          @click="editPromoCode()"
        >
          <gl-icon
            :height="20"
            :name="'edit'"
            :width="20"
          />
        </div>
        <div
          v-if="!tableItemData.enabled" 
          class="promo__cntrl-item promo__cntrl-item--turn-on"
          @click="turnOnPromoCode()"
        >
          <gl-icon
            :height="24"
            :name="'turn-on'"
            :width="24"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import GlIcon from '@/components/gl-icon'

// utils
import { formatDate } from '@/utils/format-date'

export default {
  components: {
    GlIcon,
  },
  props: {
    tableItemData: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    formatDate,
    editPromoCode() {
      this.$emit('editClick')
    },
    copyLink() {
      this.$emit('copyLink')
    },
    generateQRCode() {
      this.$emit('generateQRCode')
    },
    copyCode() {
      this.$emit('copyCode')
    },
    turnOnPromoCode() {
      this.$emit('turnOnPromoCode')
    },
  },
}
</script>

<style scoped>
.table-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
}
.table-item + .table-item {
  border-top: 1px solid var(--dark-grey-d-3);
}
.table-item__row {
  max-width: calc(50% - 200px);
  flex: 0 1 calc(50% - 200px);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-item__row--first {
  padding-left: 24px;
  position: relative;
}
.table-item__row--first:before {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid var(--dark);
  border-radius: 100%;
  background-color: var(--lipstick);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.table-item__row--first.table-item__row--enabled:before {
  background-color: var(--green);
}
.table-item__row--auto {
  flex: 0 0 176px;
  max-width: 100%;
}
.table-item__row-label {
  display: none;
  font-weight: 700;
}
.table-item__name-pointer {
  display: inline-block;
  max-width: 100%;
  padding-top: 4px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  pointer-events: none;
}
.table-item__row--enabled .table-item__name-pointer {
  pointer-events: all;
}

.promo__cntrl-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.promo__cntrl-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--reflex-bluet);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.promo__cntrl-item:hover {
  background-color: var(--reflex-bluet-transparent);
}
.promo__cntrl-item--turn-on {
  border: 1px solid var(--green);
}
.promo__cntrl-item--turn-on:hover {
  background-color: var(--green-25);
}

@media (max-width: 1023px) {
  .table-item {
    display: block;
  }
  .table-item__row {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 100%;
  }
  .table-item__row + .table-item__row {
    margin-top: 16px;
  }
  .table-item__row-label {
    display: block;
  }
}

@media (max-width: 767px) {
  .table-item__row {
    display: block;
  }
  .promo__cntrl-wrap {
    justify-content: flex-start;
  }
}
</style>