<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    class="gl-modal__show-qrcode"
    closable
    full-buttons
    :max-height-form="windowHeight < 650 ? '40vh' : '70dvh'"
    ok-only
    :overflowing-form="windowHeight < 650"
    submit-title="Close"
    :title="title"
    width="480"
    v-on="$listeners"
    @submit="$emit('close')"
  >
    <template #header>
      <div class="qr-code__header">
        <div class="qr-code__header-title">
          {{ title }}
        </div>
        <div class="qr-code__header-tokens">
          <gl-icon
            :height="isMobile ? 18 : 24"
            name="lookups-blue"
            :width="isMobile ? 18 : 24"
          />
          <div>
            {{ tokens }}
          </div>
        </div>
      </div>
    </template>
    <template #mainSlot>
      <div class="qr-code__content">
        <qr-code
          :size="1000"
          :text="linkForGenerationQrcode"
        />
      </div>
    </template>
  </gl-modal>
</template>

<script>
// components
import GlIcon from '@/components/gl-icon'
import GlModal from '@/components/gl-modal'
import 'vue2-datepicker/index.css'

// mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlModal,
    GlIcon,
  },
  mixins: [deviceWidthMixin],
  props: {
    linkForGenerationQrcode: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tokens: {
      type: [Number, String],
      default: null,
    },
  },
}
</script>

<style scoped>
.qr-code__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  min-width: 100%;
  padding: 24px;
  font-size: 24px;
  font-weight: bold;
  padding: 24px;
}
.qr-code__header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.qr-code__header-tokens {
  display: flex;
  align-items: center;
  gap: 8px;
}
.qr-code__content {
  max-height: 100%;
  padding: 24px;
}
.gl-modal__show-qrcode::v-deep .form-content-wrapper {
  display: flex;
}
.qr-code__content::v-deep > div {
  height: 100%;
}
.gl-modal__show-qrcode::v-deep img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-height: 767px) {
  .qr-code__header,
  .qr-code__content {
    padding: 16px;
  }
  .qr-code__header {
    font-size: 18px;
  }
}
</style>