<template>
  <div class="admin-panel__content-wrapper">
    <div class="admin-panel__content">
      <div class="admin-panel__content-header">
        <div class="table-item__row">
          Name
        </div>
        <div class="table-item__row">
          Expire Date
        </div>
        <div class="table-item__row table-item__row--auto">
          Tokens
        </div>
        <div class="table-item__row table-item__row--auto" />
      </div>
      <template v-if="promoData.length">
        <PromoTableItem 
          v-for="promo in promoData"
          :key="promo._id"
          :table-item-data="promo"
          @copyCode="copyData(promo)"
          @copyLink="copyData(promo, 'link')"
          @editClick="editPromo(promo)"
          @generateQRCode="generateQRCode(promo)"
          @turnOnPromoCode="turnOnPromoCode(promo)"
        />
      </template>
      <template v-else>
        <div class="admin-panel__content-empty">
          There is no data for view
        </div>
      </template>
    </div>

    <div class="admin-panel__pagination">
      <Paginator 
        v-if="adminPanelPromoTotal > adminPanelPromoPageSize"
        ref="paginatorPromo"
        :rows="adminPanelPromoPageSize" 
        :total-records="adminPanelPromoTotal"
        @page="pageChange" 
      />
    </div>

    <CreatePromoModal
      v-model="createPromoModalState"
      :modal-state="createPromoModalState"
      @close="createPromoModalState = false"
      @createNewPromo="createNewPromo"
    />
    <ShowGeneratedQRCodeModal
      v-model="viewGeneratedQRCodeModalState"
      :link-for-generation-qrcode="linkForQRCode"
      :title="titleForQRCode"
      :tokens="tokensForQRCode"
      @close="viewGeneratedQRCodeModalState = false"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import PromoTableItem from './components/PromoTableItem.vue'
import CreatePromoModal from './modals/CreatePromoModal.vue'
import ShowGeneratedQRCodeModal from './modals/ShowGeneratedQRCodeModal.vue'
import Paginator from 'primevue/paginator'

// Utils
import { copyText } from '@/utils/copy-text'
import { scrollToSelector } from '@/utils/scroll-to'

export default {
  name: 'AdminPanelPromo',
  components: {
    PromoTableItem,
    CreatePromoModal,
    ShowGeneratedQRCodeModal,
    Paginator,
  },
  data() {
    return {
      pageNumber: 1,
      createPromoModalState: false,
      viewGeneratedQRCodeModalState: false,
      linkForQRCode: '',
      titleForQRCode: '',
      tokensForQRCode: '',
    }
  },
  computed: {
    ...mapGetters('adminPanel', ['promoData', 'adminPanelPromoPageSize', 'adminPanelPromoTotal', 'adminFilterValue', 'adminModalPromoOpen', 'activePromo', 'isEditMode']),
  },
  watch: {
    adminFilterValue() {
      this.sendAllParams()
    },
    adminModalPromoOpen() {
      this.createPromoModalState = true
    }
  },
  mounted() {
    this.sendAllParams()
  },
  methods: {
    ...mapActions('adminPanel', ['getPromos', 'createPromoCode', 'updatePromoCode']),
    ...mapMutations('adminPanel', ['SWITCH_FILTER_CLEAR_TRIGGER', 'SET_FILTER_VALUE', 'SWITCH_CREATE_PROMO_MODAL_TRIGGER']),
    copyText,
    scrollToSelector,
    scrollToSelectorItem(wrap, aim) {
      this.scrollToSelector(
        wrap,
        aim,
        100,
      )
    },
    pageChange({ page }) {
      if(page > 0) {
        this.scrollToSelectorItem('.main', '.admin-panel__content-wrapper')
      }
      this.pageNumber = page + 1
      this.sendAllParams()
    },
    sendAllParams() {
      this.getPromos(
        {
          pageNumber: this.pageNumber,
          name: this.adminFilterValue,
        }
      )
    },
    createNewPromo(newPromo) {
      this.createPromoModalState = false

      if(this.isEditMode) {
        const diffPromo = {
          _id: this.activePromo._id
        }

        const keysToCheck = ['name', 'expiresAt', 'tokens']

        keysToCheck.forEach(key => {
          if (newPromo[key] !== this.activePromo[key]) {
            diffPromo[key] = newPromo[key];
          }
        })

        this.updatePromoCode(diffPromo)
      } else {
        this.createPromoCode(newPromo)
      }
    },
    editPromo(promo) {
      this.SWITCH_CREATE_PROMO_MODAL_TRIGGER(promo)
    },
    generateLink(name) {
      return `${window.location.origin}/payment?promo=${name}`
    },
    generateQRCode(promo) {
      this.titleForQRCode = promo.name
      this.tokensForQRCode = promo.tokens
      this.linkForQRCode = this.generateLink(promo.name)
      this.viewGeneratedQRCodeModalState = true
    },
    turnOnPromoCode(promo) {
      this.updatePromoCode({...promo, enabled: true})
    },
    async copyData(promo, type) {
      this.copyText(type === 'link' ? this.generateLink(promo.name) : promo.name)
        .then(() => {
          this.$toast.add({
            severity: 'info',
            summary: 'Success Message',
            detail: 'Copied!',
            life: 3000,
          })
        })
    },
  },
}
</script>

<style scoped>
.table-item__row {
  max-width: calc(50% - 200px);
  flex: 0 1 calc(50% - 200px);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-item__row--auto {
  flex: 0 0 176px;
  max-width: 100%;
}
</style>